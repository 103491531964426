import { createStore } from 'vuex'
import api from "../public/api";
import WebSocketService from "../public/websocket";

export default createStore({
  state: {
    userinfo:null,
    message:null,
    websocketService: null,
    keepAliveInclude:[],
  },
  getters: {
    getUserInfo(state) {
      return state.userinfo;
    },
    getWebSocketService(state) {
      return state.websocketService;
    },
    getMessage(state){
      return state.message;
    },
    keepAliveInclude: state => state.keepAliveInclude,
  },
  mutations: {
    setUserInfo(state, userinfo) {
      state.userinfo = userinfo;
    },
    setMessages(state, data) {
      state.message = data;
      // console.log(data)
    },
    setWebsocketService(state, websocketService) {
      state.websocketService = websocketService;
    },
    SET_KEEPALIVEINCLUDE:(state, keepAliveInclude) => {
      state.keepAliveInclude = keepAliveInclude;
    }
  },
  actions: {
    setKeepAliveInclude({ commit }, keepAliveInclude){
      commit("SET_KEEPALIVEINCLUDE", keepAliveInclude)
    },
    updateUserInfo({ commit }, userinfo) {
      commit('setUserInfo', userinfo);
    },
    async fetchUserInfo({ commit },callback) {
      api.resetBeforeRequest()
      api.post('/User/UserToken', {}).then(res => {
        if (!res.Success) {
          commit('setUserInfo', null);
        } else {
          res.Data.Avatar=`${api.baseURL}/User/GetAvatar?token=${encodeURIComponent(localStorage.getItem('token'))}&random=${Math.random()}`
          commit('setUserInfo', res.Data);
        }
        // 在异步操作完成后调用回调函数
        if (typeof callback === 'function') {
          callback(res);
        }
      }).catch(err=>{
        console.info(err)
        commit('setUserInfo', null);
      })
    },
    initWebSocket({ commit, state }) {
      if (!state.websocketService) {
        const websocketService = new WebSocketService();
        websocketService.init();
        commit('setWebsocketService', websocketService);
      }
    },
    closeWebSocket({ commit, state }) {
      if (state.websocketService) {
        state.websocketService.closeWebSocket();
        commit('setWebsocketService', null);
      }
    },
  },
  modules: {
   
  }
})
