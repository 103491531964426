import axios from 'axios';
import { ElMessage } from 'element-plus';
import { ElLoading } from 'element-plus'
// import { useRouter } from 'vue-router';

let baseURL=''
let edocUrl=''
if (process.env.NODE_ENV === 'development') {
  // 在开发环境中执行的代码
  baseURL = 'http://192.168.252.17:37669';
  edocUrl="http://192.168.251.190"
} else if (process.env.NODE_ENV === 'production') {
  // 在生产环境中执行的代码
  baseURL = 'https://mlc.edoc2.com';
  edocUrl="https://kb.edoc2.com"
}
let loadingInstance;
// 在 Vue 组件或实例中定义 beforeRequest 方法
let defaultBeforeRequest = function () {
  loadingInstance = ElLoading.service({
    text: '正在处理',
    background: 'rgba(255, 255, 255, 0.7)',
  });
};
let beforeRequest = defaultBeforeRequest;
// 创建一个 axios 实例
const instance = axios.create({
  baseURL: edocUrl, // 你的 API 地址
  timeout: 20000, // 超时时间
});

// 添加请求拦截器
instance.interceptors.request.use(
  config => {
    // 在请求发送前可以进行一些处理，比如添加请求头等
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    beforeRequest && typeof beforeRequest === 'function' && beforeRequest();
    return config;
  },
  error => {
    ElMessage.error({
      message: error || '请求服务失败',
      showClose: false,
      duration:3000
    });
    return Promise.reject(error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  response => {
    // 在接收到响应后可以进行一些处理，比如处理返回的数据等
    const responseData = response.data;
    if (responseData.Success === false) {
      if(responseData.Error =='tokenInvalidation'){
        handleTokenInvalidation()
      }else{
        ElMessage.error({
          message: responseData.Error || '请求服务失败',
          showClose: false,
          duration:3000
        });
        // return Promise.reject(new Error(responseData.Error || '请求服务失败'));
      }
    }
    beforeRequest = defaultBeforeRequest;
    if(loadingInstance)
      loadingInstance.close();
    return responseData;
  },
  error => {
    ElMessage.error({
      message: error || '请求服务失败',
      showClose: false,
      duration:3000
    });
    beforeRequest = defaultBeforeRequest;
    if(loadingInstance)
      loadingInstance.close();
    // return Promise.reject(error);
  }
);

// 封装 GET 请求方法
export const get = (url, params = {}) => {
  return instance.get(url, { params });
};

// 封装 POST 请求方法
export const post = (url, data = {}) => {
  return instance.post(url, data);
};

// 修改 beforeRequest 方法为默认的加载中效果
export const setDefaultBeforeRequest = () => {
  beforeRequest = defaultBeforeRequest;
};

// 设置 beforeRequest 为 null 可以取消加载中效果
export const resetBeforeRequest = (method) => {
  if (typeof method === 'function') {
    beforeRequest = method;
  }else{
    beforeRequest = null;
  }
};

export { baseURL };

// 可以继续添加其他请求方法，比如 PUT、DELETE 等

export default {
  get,
  post,
  baseURL,
  edocUrl,
  resetBeforeRequest
};

import router from "../router";
function handleTokenInvalidation() {
  localStorage.clear();
  router.replace('/login');
  window.history.pushState({}, '', '/'); // 将当前页面添加到历史记录
  window.history.replaceState({}, '', '/'); // 替换当前页面的历史记录
}