<template>
    <div class="message">
        <el-empty description="暂无消息" v-if="getMessage.data.length < 1" />
        <div v-else>
            <div class="msg-operation">
                <el-icon size="18px">
                    <ChatDotSquare />
                </el-icon>
                <el-button link @click="haveRead('')">全部已读</el-button>
            </div>
            <el-tabs v-model="activeName" @tab-click="haveRead(activeName)">
                <el-tab-pane v-for="item in getMessage.data" :key="item.Type" :label="item.Type" :name="item.Type">
                    <template #label>
                        <el-badge :value="getMessage.data.find(a => a.Type == item.Type).Total"
                            :hidden="getMessage.data.find(a => a.Type == item.Type).Total === 0" :max="99">
                            {{ item.Type }}
                        </el-badge>
                    </template>
                    <div class="notice-main">
                        <ul v-infinite-scroll="get" style="overflow: auto" class="infinite-list" infinite-scroll-delay="0">
                            <li v-for="msg in datasource(activeName).data" :key="msg.masterId" class="list-item" shadow="hover" @click="detail(msg)">
                                <p class="list-item-content">{{ msg.content }}</p>
                                <p class="list-item-time">{{ $public.dateFormat(msg.createTime) }}</p>
                                <!-- <i v-if="msg.read=='否'" class="point"></i> -->
                            </li>
                            <el-button v-if="datasource(activeName).loading" :loading="datasource(activeName).loading" link
                                type="info" color="#6c74f7" class="loadingbtn">加载中...</el-button>
                            <p v-else class="nomore link">没有更多了~</p>
                        </ul>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
export default {
    name: 'msgCtrl',
    data() {
        return {
            activeName: '',
            load: false,
            list: [{
                type: '告警',
                data: [],
                loading: true,
                index: 1,
                total: 0,
            }, {
                type: '工单',
                data: [],
                loading: true,
                index: 1,
                total: 0,
            }, {
                type: '投诉',
                data: [],
                loading: true,
                index: 1,
                total: 0,
            }, {
                type: '意见反馈',
                data: [],
                loading: true,
                index: 1,
                total: 0,
            }, {
                type: '系统消息',
                data: [],
                loading: true,
                index: 1,
                total: 0,
            }, {
                type: '评价',
                data: [],
                loading: true,
                index: 1,
                total: 0,
            }, {
                type: '巡检',
                data: [],
                loading: true,
                index: 1,
                total: 0,
            }],
            urls:[{name:'工单',url:'/workorder'
            },{name:'评价',url:'/workorder'
            },{name:'告警',url:'/workorder'
            },{name:'巡检',url:'/patroldetail'
            },{name:'意见反馈',url:'/feedbackdetail'
            },{name:'投诉',url:'/complaintdetail'
            },{name:'培训',url:'/traindetail'
            }]
        }
    },
    computed: {
        getMessage() {
            const res = this.$getMessage();
            if (res && res.data) {
                if (!this.load) {
                    this.default(res)
                }
                return res;
            } else {
                return { data: [] };
            }
        }
    },
    mounted() {
        // this.activeName = this.getMessage[0].Type
    },
    methods: {
        detail(item){
            console.log(item)
        },
        haveRead(type) {
            this.api.resetBeforeRequest()
            this.api.post('/Share/HandleReadInfo',{type})
            if (type == '') {
                this.getMessage.total=0
                this.getMessage.data.map(i=>{
                    i.Total = 0
                })
            }else{
                let count = 0
                this.getMessage.data.find(a=>a.Type==type).Total=0
                this.getMessage.data.map(item=>{
                    count += item.Total
                })
                this.getMessage.total=count
            }
        },
        default(res) {
            if(res.data.length<1)
                return
            this.activeName = res.data[0].Type
            this.load = true
        },
        handleList(datas) {
            datas.forEach(data => {
                const type = data.Type
                const exist = this.list.find(_ => _.type == type)
                if (!exist) {
                    this.list.push({
                        loading: true,
                        total: 0,
                        data: [],
                        type
                    })
                }
            })
        },
        datasource(type) {
            if (!this.list.find(_ => _.type == type)) {
                return { data: [], loading: true, index: 1 }
            }
            return this.list.find(_ => _.type == type)
        },
        get() {
            const entity = this.list.find(a => a.type == this.activeName)
            if (!entity)
                return
            if (!entity.loading) {
                return;
            }
            this.api.resetBeforeRequest()
            this.api.post('/Share/MessageList', {
                index: entity.index,
                size: 10,
                type: entity.type
            }).then(res => {
                if (!res.Success) {
                    return;
                }
                const total = res.Total
                this.list.find(a => a.type == this.activeName).total = total
                res.Data.forEach(item => {
                    this.list.find(a => a.type == this.activeName).data.push(item)
                })
                const dataLength = this.list.find(a => a.type == this.activeName).data.length
                if (dataLength >= total) {
                    this.list.find(a => a.type == this.activeName).loading = false
                } else {
                    this.list.find(a => a.type == this.activeName).index++
                }
            })
        },
    }
}
</script>
<style>
@import "../../css/message.css";
</style>