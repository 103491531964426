// import { createRouter, createWebHistory  } from 'vue-router'
import { createRouter, createWebHashHistory  } from 'vue-router'
import NotFound from '@/views/NotFound.vue';

const routes = [
  {
    path: '/home',
    name: 'index',
    meta:{
      keepAlive:true
    },
    component: () => import('../views/HomeView.vue'),
    props: { activeIndex: '/' }
  },
  {
    path: '/',
    name: '首页',
    component: () => import('../views/home/IndexView.vue'),
  },
  {
    path: '/login',
    name: '登录',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/myself',
    name: '个人中心',
    component: () => import('../views/myself/selfinfo.vue')
  },
  {
    path: '/workorder',
    name: '工单',
    component: () => import('../views/aftersale/workOrder.vue'),
    props: { activeIndex: 'workorder' }
  },
  {
    path: '/aftersale',
    name: '售后服务',
    component: () => import('../views/aftersale/indexView.vue'),
    props: { activeIndex: 'workorder' }
  },
  {
    path: '/activityDetail',
    name: '活动详情',
    component: () => import('../views/comm/activityDetail.vue')
  },
  {
    path: '/preview',
    name: '文件预览',
    component: () => import('../views/comm/edocPreview.vue')
  },
  {
    path: '/wiki',
    name: '知识库列表',
    component: () => import('../views/wiki/wikiList.vue')
  },
  {
    path: '/wikidetail',
    name: '知识库详细',
    component: () => import('../views/wiki/wikiDetail.vue')
  },
  {
    path: '/message',
    name: '消息中心',
    component: () => import('../views/comm/message.vue')
  },
  {
    path: '/messagecenter',
    name: '消息中心',
    meta:{
      keepAlive:true
    },
    component: () => import('../views/comm/messagePage.vue')
  },
  {
    path: '/orders',
    name: '订单',
    meta:{
      keepAlive:true
    },
    component: () => import('../views/orders/orderList.vue')
  },
  {
    path: '/messagedetail',
    name: '纤细详情',
    component: () => import('../views/comm/messageDetail.vue')
  },
  {
    path: '/evaluate',
    name: '评价',
    component: () => import('../views/comm/evaluatePage.vue')
  },
  {
    path: '/classroom',
    name: '在线课堂',
    component: () => import('../views/comm/classRoom.vue')
  },
  { path: '/:pathMatch(.*)', component: NotFound }
]

const router = createRouter({
  // history: createWebHistory(), 
  history: createWebHashHistory(), // 使用 hash 模式
  routes,
})

export default router
