//热门课程基础文件夹
let popularCoursesFolderId=null
if (process.env.NODE_ENV === 'development') {
    // 在开发环境中执行的代码
    popularCoursesFolderId = 316226;
  } else if (process.env.NODE_ENV === 'production') {
    // 在生产环境中执行的代码
    popularCoursesFolderId = 1045730;
  }



export default {
    popularCoursesFolderId
};