import api from "./api";
const http = api.baseURL.split("://");
const wsUrl = `${http[0] == "https" ? "wss" : "ws"}://${
  http[1]
}/WebSockets/GetNotReadCount?token=${encodeURIComponent(localStorage.getItem("token"))}`;

import store from "./../store";
class WebSocketService {
  constructor() {
    this.url = wsUrl;
    this.websocket = null;
    this.connected = false;
    this.heartbeatInterval = null;
    this.reconnectInterval = null;
    this.canReconnect = false;
  }

  init() {
    this.websocket = new WebSocket(this.url);

    this.websocket.onopen = () => {
      console.log("连接成功");
      clearInterval(this.reconnectInterval);
      this.connected = true;
      this.canReconnect = true;
      const token = encodeURIComponent(localStorage.getItem("token"));
      const payload = { token };
      this.websocket.send(JSON.stringify(payload));
      this.startHeartbeat();
    };

    this.websocket.onclose = () => {
      this.connected = false;
      if (this.canReconnect) {
        this.canReconnect = false;
        this.reconnectWebSocket();
      }
    };

    this.websocket.onmessage = (event) => {
      this.handleIncomingMessage(event.data);
    };
  }

  startHeartbeat() {
    this.sendHeartbeat();
    this.heartbeatInterval = setInterval(() => {
      this.sendHeartbeat();
    }, 5000); // 每隔 5 秒发送一次心跳
  }

  sendHeartbeat() {
    if (this.connected) {
      const token = encodeURIComponent(localStorage.getItem("token"));
      const payload = { token,push:true };
      this.websocket.send(JSON.stringify(payload));
    }
  }

  validateConnection(callback) {
    if (!this.connected) {
      console.log("连接未建立，正在尝试重新连接");
      this.init();
      setTimeout(() => {
        this.validateConnection(callback);
      }, 10000); // 每隔 1 秒尝试重新验证连接
    } else {
      callback();
    }
  }

  reconnectWebSocket() {
    clearInterval(this.heartbeatInterval);
    if (!this.connected) {
      this.reconnectInterval = setInterval(() => {
        console.log("连接断开正在尝试重新连接");
        this.init(); // 重新初始化连接
      }, 10000); // 每隔 3 秒尝试重连一次
    }
  }

  handleIncomingMessage(res) {
    try {
      const result = JSON.parse(res);
      // 在这里直接处理接收到的消息数据
      if(!result.Success){
        return
      }
      const data=result.Data
      const total = data.reduce((accumulator, currentObject) => {
        return accumulator + currentObject.Total;
      }, 0);
      data.sort((a, b) => {
				return a.PushTime - b.PushTime
			})
      const messages={
        total,
        data
      }
      store.commit('setMessages', messages);
    } catch (error) {
      console.error("Error parsing incoming message:", error);
    }
  }

  closeWebSocket() {
    clearInterval(this.heartbeatInterval);
    clearInterval(this.reconnectInterval);
    this.canReconnect = false;
    this.websocket.close();
    console.log("已清除消息通信");
  }
}

export default WebSocketService;
