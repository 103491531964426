import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "element-plus/dist/index.css";
import ElementPlus from "element-plus";
import { ElMessage } from "element-plus";
import { ElMessageBox } from "element-plus";
import { ElLoading } from 'element-plus'
import api from "./public/api";
import config from "./public/config";
import edocApi from "./public/edocApi";
import $public from "./public/common";
import "./css/global.css";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import Nav from "./views/comm/nav.vue";

const app = createApp(App);

// 将 Element Plus 插件注册到应用中
app.use(ElementPlus);

app.config.globalProperties.$public = $public;
// 将 ElMessage 组件添加到全局属性中
app.config.globalProperties.$message = ElMessage;
app.config.globalProperties.$messagebox = ElMessageBox;
app.config.globalProperties.$loading = ElLoading;
//用户信息处理
app.config.globalProperties.$fetchUserInfo = (callback) =>
  store.dispatch("fetchUserInfo", callback);
app.config.globalProperties.$getUserInfo = () => store.getters.getUserInfo;
app.config.globalProperties.$updateUserInfo = (userinfo) =>
  store.dispatch("updateUserInfo", userinfo);
//消息处理
app.config.globalProperties.$getMessage = () => store.getters.getMessage;

// 全局设置 ElMessage 的样式
app.directive("message-style", {
  mounted(el) {
    el.querySelector(".el-message__group").style.backgroundColor = "#575a5c"; // 设置背景色
    el.querySelector(".el-message").style.color = "#fff"; // 设置文字颜色
  },
});

// 将 api 对象添加到全局属性中
app.config.globalProperties.api = api;
app.config.globalProperties.edocApi = edocApi;
app.config.globalProperties.config = config;
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

app.component("nav-comm", Nav);
const instance = app.use(store).use(router).mount("#app");
window.init = instance;

//验证token
validToken();
function validToken() {
  const storedToken = localStorage.getItem("token");
  if (storedToken) {
    store.dispatch("fetchUserInfo", (res) => {
      $public.setTokenCookie(storedToken,'mlctoken')
      if (res) {
        if (!sessionStorage.getItem("tokenVerified")){
          localStorage.removeItem('activeIndex')
          router.replace("/");
        }
        store.dispatch("initWebSocket");
        sessionStorage.setItem("tokenVerified", true);
      } else {
        router.replace("/login");
      }
    });
  } else {
    router.replace("/login");
  }
}
