import api from "./api";
import edocApi from "./edocApi";

//创建记录
function createRecord(page,content,remark=""){
  api.resetBeforeRequest()
  api.post('/Share/CreateRecord',{page,content,remark,source:'web'})
}

//格式化处理
function dateFormat(time, type = 0) {
  let date = new Date(time);
  let year = date.getFullYear();
  // 在日期格式中，月份是从0开始的，因此要加0，使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
  let month =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1;
  let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  let hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
  let minutes =
    date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  let seconds =
    date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  // 拼接
  if (type == 0) {
    return (
      year +
      "-" +
      month +
      "-" +
      day +
      " " +
      hours +
      ":" +
      minutes +
      ":" +
      seconds
    );
  }
  if (type == 1) {
    return year + "年" + month + "月" + day + "日";
  }
  if (type == 2) {
    return year + "-" + month + "-" + day;
  }
  if (type == 3) {
    return year + month + day;
  }
  //return year + "-" + month + "-" + day;
}

function convertDate(date,fmt) {
  let ret;
  if(!fmt){
    fmt="YYYY-mm-dd HH:MM:SS"
  }
  if(typeof date === 'string'){
    date=new Date(date)
  }
  const opt = {
    "Y+": date.getFullYear().toString(),        // 年
    "m+": (date.getMonth() + 1).toString(),     // 月
    "d+": date.getDate().toString(),            // 日
    "H+": date.getHours().toString(),           // 时
    "M+": date.getMinutes().toString(),         // 分
    "S+": date.getSeconds().toString()          // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (let k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
    }
  }
  return fmt;
}

// 持久集成的令牌(登录kb用)
// const integrationToken = ""; //0034934c411e65c846808e7ac5b9109e58bf

// setTokenCookie('0034d6e0d56a5b4e46d7a071acc358826494');
// 设置令牌到 cookie
function setTokenCookie(token,str='token') {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + 7); // 令牌有效期为7天

  document.cookie = `${str}=${token}; expires=${expirationDate.toUTCString()}; path=/`;
}

function verifyEmail(str) {
  if (str == '')
    return false;
  if (!/^([a-zA-Z0-9]+[_|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/.test(str))
    return false;
  return true;
}

function getIframeUrl(url){
  const token=localStorage.getItem('edocToken')
  const finalUrl=`${edocApi.edocUrl}/jump.html?token=${token}&returnUrl=${url}`
  return api.baseURL+`/tpl/Edoc/default.html?url=${encodeURIComponent(finalUrl)}`
}

export default {
  dateFormat,
  verifyEmail,
  setTokenCookie,
  convertDate,
  createRecord,
  getIframeUrl
};
