<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/login">About</router-link>
  </nav> -->
  <keep-alive :include="keepAliveInclude">
      <router-view />
    </keep-alive>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters([
      'keepAliveInclude',
    ])
  },
  data() {
    return {
      messageListenerRegistered: false,
    }
  },
  mounted() {
    if (!this.messageListenerRegistered) {
      // 注册消息监听器
      window.addEventListener('message', this.handleMessage);
      this.messageListenerRegistered = true;
    }
    this.validToken()
    window.addEventListener('beforeunload', this.closeWebSocket);
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  },
  created() {

  },
  methods: {
    validToken() {
      const storedToken = localStorage.getItem("token");
      if (storedToken) {
        this.$store.dispatch("fetchUserInfo", (res) => {
          this.$public.setTokenCookie(storedToken, 'mlctoken')
          if (res) {
            if (!sessionStorage.getItem("tokenVerified")) {
              this.$router.replace("/");
            }
            this.$store.dispatch("initWebSocket");
            sessionStorage.setItem("tokenVerified", true);
          } else {
            this.$router.replace("/login");
          }
        });
      } else {
        this.$router.replace("/login");
      }
    },
    handleMessage(event) {
      const messageType = event.data.category;
      switch (messageType) {
        case '巡检评价':
          this.handleEvaluate(event.data);
          break;
      }
    },
    handleEvaluate(item) {
      this.$router.push({ path: '/evaluate', query: { Id: item.id, type: item.type } })
    },
    closeWebSocket() {
      // 在页面离开时关闭WebSocket连接
      if (this.$route.name !== '登录') {
        this.$store.dispatch('closeWebSocket');
      }
    },
    handleVisibilityChange() {
      // 处理页面可见性变化
      if (document.hidden) {
        // 页面不可见，关闭WebSocket连接
        this.closeWebSocket();
      } else {
        // 页面重新可见，重新连接WebSocket或执行其他操作
        if (this.$route.name !== '登录') {
          this.$store.dispatch('initWebSocket');
        }
      }
    },
  },
  beforeUnmount() {
    window.removeEventListener('message', this.handleMessage);
    this.messageListenerRegistered = true;
    window.removeEventListener('beforeunload', this.closeWebSocket);
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
  }
}
</script>


<style lang="scss">
* {
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, 'PingFang SC', "Hiragino Sans GB", "Hiragino Sans GB W3", "Microsoft YaHei", Arial, sans-serif;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
