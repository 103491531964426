<template>
    <div class="header">
        <div class="header_width">
            <div class="header_m">
                <div class="header_icon">
                    <div @click="$router.push('/')" class="header_icon_item">
                        <img src="../../assets/mlc-logo.svg"/>
                        <p>为您提供优质的服务</p>
                    </div>
                    <div class="navbar">
                        <el-menu mode="horizontal" :default-active="activeIndex">
                            <el-menu-item v-for="link in visibleNavLinks" :key="link.path" :index="link.index"
                                @click="handleSelect(link)">
                                {{ link.text }}
                            </el-menu-item>
                        </el-menu>
                    </div>
                </div>
                <div class="header_end">
                    <el-badge :value="getMessage.total" style="margin-right: 30px;" class="header_notice"
                        @click="handleSelect('/messagecenter', true)" :hidden="getMessage.total === 0" :max="99" title="消息中心">
                        <el-icon size="24">
                            <Bell />
                        </el-icon>
                    </el-badge>
                    <el-popover :width="260" trigger="hover">
                        <template #reference>
                            <div class="header_avatar">
                                <div :style="getBg(computedUserInfo.Status)" class="user_status_bg">
                                </div>
                                <el-avatar :size="40" :src="computedUserInfo.Avatar"></el-avatar>
                                <p class="user_name">{{ computedUserInfo.Name }}
                                </p>
                            </div>
                        </template>
                        <ul class="user_center">
                            <li v-if="computedUserInfo.Status == '已认证'">
                                <div class="user_pass">
                                    <el-avatar :size="60" :src="computedUserInfo.Avatar"></el-avatar>
                                    <div class="user_pass_r">
                                        <p :title="computedUserInfo.MisClientName">{{ computedUserInfo.MisClientName }}</p>
                                        <p :title="computedUserInfo.Position">{{ computedUserInfo.Position }}</p>
                                        <!-- <template v-else>
                                            <i class="user_name_i" :style="getStatusStyle(computedUserInfo.Status)">{{
                                                computedUserInfo.Status
                                            }}</i>
                                        </template> -->
                                    </div>
                                </div>
                            </li>
                            <i class="menu_line"></i>
                            <li class="menu_hover menu_left" @click="handleSelect('/myself', true)">个人中心</li>
                            <i class="menu_line"></i>
                            <li class="log_out menu_hover menu_left" @click="loginout()">
                                退出登录
                            </li>
                        </ul>
                    </el-popover>
                </div>
            </div>
        </div>
    </div>
    <div class="dialog-custom">
        <el-dialog width="800" :destroy-on-close="true" title="消息中心" v-model="dialogVisible">
            <message />
        </el-dialog>
    </div>
</template>
<script>
import message from '../comm/message.vue';
export default {
    name: 'navComm',
    components: {
        message,
    },
    data() {
        return {
            navLinks: [
                { path: `/`, text: '首页', index: 0, show: true },
                { path: `/workorder`, text: '工单', index: 1, show: true },
                { path: `/aftersale`, text: '售后服务', index: 2, show: true },
                { path: `/orders`, text: '订单', index: 3, show: true },
            ],
            activeIndex: null,
            defaultIcon: 'el-icon-user',
            avatar: '',
            userinfo: {},
            statusColor: [
                { status: '未认证', color: '#e7290f' },
                { status: '认证中', color: 'orange' },
                { status: '已认证', color: '#3562f2' },
            ],
            interval: null,
            msgNum: 0,
            dialogVisible: false,
        };
    },
    created() {
        // this.avatar = `${api.baseURL}/User/GetAvatar?token=${encodeURIComponent(localStorage.getItem('token'))}`
        //没10分钟更新一下用户信息
        this.interval = setInterval(() => {
            this.fetchUserInfo();
        }, 10 * 60 * 1000);
        this.activeIndex=parseInt(localStorage.getItem('activeIndex'))||0
    },
    computed: {
        visibleNavLinks() {
            return this.navLinks.filter(link => link.show);
        },
        computedUserInfo() {
            const userinfo = this.$getUserInfo();
            if (!userinfo) {
                this.fetchUserInfo();
            }
            return userinfo ? userinfo : { Status: null }; // 设置默认值以避免空指针错误
        },
        getMessage() {
            const res = this.$getMessage()
            return res ? res : { total: 0 };
        },
        shouldShowBadge() {
            return this.msgNum > 0;
        },
    },
    methods: {
        openmessage() {
            // this.dialogVisible = true
            
            this.$router.push('/messagecenter')
        },
        verifySuccess() {
            this.$store.dispatch('initWebSocket');
        },
        fetchUserInfo() {
            this.$fetchUserInfo(res => {
                if (res.Success) {
                    this.userinfo = res.Data;
                    if (this.userinfo.Status === '已认证') {
                        this.navLinks.forEach(i => {
                            i.show = true;
                        });
                    }
                }
            });
        },
        loginout() {
            this.$messagebox.confirm(`确认退出？`, '提示', {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                dangerouslyUseHTMLString: true
            }).then(() => {
                localStorage.clear()
                this.$router.replace('/login');
                this.$store.dispatch('closeWebSocket');
                this.$store.commit('resetAllState');
                sessionStorage.removeItem('sessionMenu')
            }).catch(() => {

            });
        },
        getBg(status) {
            if (status == '已认证') {
                return { background: `url(${require('@/assets/public/已认证.svg')})` };
            }
            return { background: `url(${require('@/assets/public/未认证.svg')})` };
        },
        getStatusStyle(status) {
            const matchingStatus = this.statusColor.find(item => item.status === status);
            if (matchingStatus) {
                return { background: matchingStatus.color };
            }
            return {};
        },
        handleSelect(path, other = false) {
            if (other) {
                path = `${path}`
                this.activeIndex=null
                localStorage.setItem('activeIndex',-1)
            }else{
                const index=this.navLinks.findIndex(a=>a.path==path)
                if(index>-1){
                    localStorage.setItem('activeIndex',index)
                }
                
            }
            this.$router.push(path)
            // this.$public.createRecord('导航',path.text)
            // this.$emit('menu-click', path);
        },
        isActiveLink(path) {
            return this.$route.path === path;
        },
    },
    // beforeUnmount() {
    //     clearInterval(this.interval);
    //     this.$store.dispatch('closeWebSocket');
    // }
}
</script>

<style>
@import "../../css/nav.css";
</style>